import Enum from './Enum.js';

export default class LeadVehicleTransmission extends Enum {
    static get entries() {
        return {
            auto: 'auto',
            manual: 'manual',
            sequential: 'sequential',
        };
    }

    static get AUTO() {
        return this.entries.auto;
    }

    static get MANUAL() {
        return this.entries.manual;
    }

    static get SEQUENTIAL() {
        return this.entries.sequential;
    }

    static get translationGroup() {
        return 'leadVehicleTransmissions';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
