<template>
    <div>
        <activix-modal
            size="xs"
            :opened="opened"
            @open="onOpen"
            @close="close"
        >
            <template slot="header">
                <h4 class="modal-title">
                    {{ $t('clientCard.ratingModalTitle') }}
                </h4>
            </template>

            <template slot="body">
                <div class="form-group">
                    <label>{{ $t('clientCard.ratingComment') }}</label>

                    <textarea class="form-control" rows="5" v-model="comment" />
                </div>
            </template>

            <template slot="footer">
                <div class="flex items-center justify-center">
                    <activix-button @click="close">
                        {{ $t('general.cancel') }}
                    </activix-button>

                    <activix-button type="primary" @click="save">
                        {{ $t('general.save') }}
                    </activix-button>

                    <button class="absolute mr-6 right-0" @click="deleteModalOpened = true" v-if="rating">
                        <icon :name="$icons.trash" class="link-grey-light | hover:text-red-500" />
                    </button>
                </div>
            </template>
        </activix-modal>

        <activix-confirm-modal
            type="error"
            :approve-text="$t('modal.delete')"
            :content="$t('clientCard.ratingDeleteMessage')"
            :opened.sync="deleteModalOpened"
            :title="$t('modal.deleting')"
            @approve="triggerDelete"
            @closed="close"
        />
    </div>
</template>

<script>
    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            rating: {
                required: true,
                validator: value => typeof value === 'number' || value === null,
            },
            ratingComment: {
                required: true,
                validator: value => typeof value === 'string' || value === null,
            },
        },

        data() {
            return {
                comment: null,
                deleteModalOpened: false,
            };
        },

        methods: {
            save() {
                this.$emit('update-lead', { rating_comment: this.comment });

                this.close();
            },

            triggerDelete() {
                this.$emit('update-lead', {
                    rating: null,
                    rating_comment: null,
                });
            },

            close() {
                this.$emit('update:opened', false);
                this.$emit('close');
            },

            onOpen() {
                this.comment = this.ratingComment;
            },
        },
    };
</script>
