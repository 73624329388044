<template>
    <div>
        <activix-modal
            size="lg"
            :name="name"
            :loading="loading"
            ref="modal"
            @before-open="onBeforeOpen"
            @closed="onClosed"
        >
            <template slot="header">
                <div>
                    <div class="flex items-center">
                        <h2 class="m-0 p-1 text-xl ">
                            {{ $t('clientCard.casl') }}
                        </h2>
                    </div>
                </div>
            </template>

            <template slot="widget">
                <div class="grid grid-cols-3 gap-12 px-8 py-4 border-b border-gray-200 bg-gray-100 bg-opacity-90" v-if="!lgLayout">
                    <div class="flex flex-col items-center text-center">
                        <div class="text-gray-600 font-semibold uppercase tracking-wider">
                            {{ $t('clientCard.status') }}
                        </div>

                        <div class="flex items-center">
                            <div class="flex items-center mr-3 text-3xl">
                                <icon name="regular/check-shield" class="text-green-500" v-if="lead.customer.isConfirmedConsent" />
                                <icon name="regular/check-shield" class="text-blue-500" v-else-if="lead.customer.isActiveConsent" />
                                <icon name="regular/check-shield" class="text-orange-500" v-else-if="lead.customer.isAboutToExpireConsent" />
                                <icon name="regular/remove-shield" class="text-red-500" v-else-if="lead.customer.isExpiredConsent" />
                            </div>

                            <div class="text-left">
                                <div class="text-lg">
                                    {{ consentStateText }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-center text-center">
                        <div class="text-gray-600 font-semibold uppercase tracking-wider">
                            {{ $t('consent.expiry') }}
                        </div>

                        <div>
                            <div class="text-lg">
                                {{ isConfirmed ? lead.customer.temporaryConsentDaysLeftString : consentLimitDate }}
                            </div>
                        </div>
                    </div>

                    <div class="flex flex-col items-center text-center">
                        <div class="text-gray-600 font-semibold uppercase tracking-wider">
                            {{ $t('consent.reason') }}
                        </div>

                        <div class="max-w-xs">
                            <div class="text-lg">
                                {{ consentReasonText }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template slot="body">
                <div>
                    <div class="space-y-8 pt-8">
                        <div class="flex flex-col justify-center items-center">
                            <h3 class="m-0 text-xl text-center">
                                {{ $t('consent.confirmConsent') }}
                            </h3>
                            <p class="m-0 text-gray-600 text-center w-2/3">
                                {{ $t('consent.confirmConsentMessage') }}
                            </p>
                        </div>

                        <div class="space-y-4 justify-center py-4 | lg:flex lg:space-x-6 lg:space-y-0">
                            <activix-tooltip :content="doubleOptInTooltip">
                                <div class="w-full lg:w-1/3">
                                    <activix-rich-radio
                                        class="w-full"
                                        :disabled="doubleOptInDisabled"
                                        :value="doubleOptInOptionSelected"
                                        @click="onDoubleOptInSelection"
                                    >
                                        <template #title>
                                            <icon name="regular/email-action-unread" />
                                            <span>{{ $t('consent.methods.email') }}</span>
                                        </template>

                                        <div class="flex flex-col flex-1">
                                            <activix-multiselect
                                                class="w-full"
                                                identifier="email"
                                                :selected="selectedEmail"
                                                :placeholder="activeLeadEmails.length ? '' : $t('consent.noValidEmail')"
                                                :options="activeLeadEmails"
                                                :disabled="hasConsentDoubleOptInDate || !doubleOptInOptionSelected"
                                                @update="updateSelectedEmail"
                                            />
                                        </div>
                                    </activix-rich-radio>
                                </div>
                            </activix-tooltip>
                            <activix-rich-radio
                                class="w-full lg:w-1/3"
                                :disabled="isConfirmed"
                                :value="manualOptionSelected"
                                @click="onManualSelection"
                            >
                                <template #title>
                                    <icon name="regular/cog" />
                                    <span>{{ $t('consent.methods.manual') }}</span>
                                </template>

                                <activix-input
                                    class="w-full"
                                    :disabled="!manualOptionSelected"
                                    :placeholder="`${$t('consent.consentNotePlaceholder')}*`"
                                    v-model="inputConsentNote"
                                />
                            </activix-rich-radio>
                        </div>
                    </div>

                    <div class="flex flex-col items-center justify-center text-gray-600" v-if="contextAccount.country === 'CA'">
                        <button class="flex items-center px-1 pt-1 | hover:text-gray-700" @click="additionalInfoToggle">
                            <span v-text="$t('consent.moreInformationsButton')" />
                            <icon class="ml-2 text-sm transition" :class="additionalInfoShown ? 'transform -rotate-180' : ''" name="regular/arrow-down-1" />
                        </button>

                        <div class="flex flex-col max-w-md mt-3 text-left" v-if="additionalInfoShown">
                            <p v-text="$t('consent.moreInformationsHeader', { date: consentLimitDate })" />
                            <ul>
                                <li v-text="$t('consent.moreInformationsFirstInfo')" />
                                <li v-text="$t('consent.moreInformationsSecondInfo')" />
                            </ul>
                            <hr class="w-full h-px border-none bg-gray-300" />
                            <div class="flex">
                                <p class="pr-1" v-text="$t('consent.moreLawInfo')" />
                                <a target="_blank" :href="lawLink" v-text="$t('consent.moreLawInfoLinkMessage')" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="footer">
                <div class="space-x-3 flex justify-end">
                    <activix-button type="default" @click="close">
                        {{ $t('general.close') }}
                    </activix-button>

                    <activix-tooltip :content="sendButtonTooltip" v-if="sendButtonVisible">
                        <span>
                            <activix-button
                                class="w-24"
                                type="primary"
                                :disabled="sendButtonDisabled"
                                :loading="$wait.is('sending.customer.consent')"
                                @click="sendConsentEmail"
                            >
                                {{ $t('modal.send') }}
                            </activix-button>
                        </span>
                    </activix-tooltip>

                    <activix-tooltip :content="saveButtonTooltip" v-if="saveButtonVisible">
                        <span>
                            <activix-button
                                class="w-24"
                                :type="isConfirmed ? 'danger' : 'primary'"
                                :disabled="saveButtonDisabled"
                                :loading="$wait.is('updating.customer.consent')"
                                @click="onSave"
                            >
                                {{ isConfirmed ? $t('phoneProvider.deactivateIt') : $t('modal.save') }}
                            </activix-button></span>
                    </activix-tooltip>
                </div>
            </template>
        </activix-modal>

        <activix-comfirm-modal
            type="warning"
            :content="$t('consent.consentWarning')"
            :title="$t('error.warning')"
            :opened.sync="warningModalOpen"
            :approve-text="$t('general.continue')"
            :deny-text="$t('general.cancel')"
            @approve="updateCustomer"
            @deny="warningModalOpen = false"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { useGlobalStore } from '@/store/store';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { isNetworkDisconnectedError } from '@/plugins/axios.js';
    import ActivixDate from '@/value-objects/ActivixDate';
    import CaslConsentType from '@/entities/CaslConsentType.js';
    import CaslConsentLimitEvent from '@/entities/CaslConsentLimitEvent.js';
    import Lead from '@/entities/Lead.js';
    import LeadEmailType from '@/entities/LeadEmailType.js';
    import ActivixComfirmModal from '@/components/elements/ActivixConfirmModal.vue';
    import ActivixRichRadio from '@/components/elements/ActivixRichRadio.vue';

    export default {
        components: {
            ActivixComfirmModal,
            ActivixRichRadio,
        },

        props: {
            name: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                additionalInfoShown: false,
                inputConsentNote: null,
                lead: new Lead(),
                loading: true,
                selectedConsentType: null,
                selectedEmail: null,
                warningModalOpen: false,
            };
        },

        computed: {
            ...mapState(useContextStore, { contextAccount: 'account' }),

            activeLeadEmails() {
                const activeLeadEmails = this.lead.emails.filter((email, index, self) => {
                    return email.valid &&
                        index === self.findIndex(duplicate => duplicate.valid && duplicate.email === email.email);
                });

                return (activeLeadEmails || []).map(email => {
                    return {
                        id: email.id,
                        label: activeLeadEmails.length === 1 ? email.email : this.getEmailTypeText(email),
                        email: email.email,
                        type: email.type,
                    };
                });
            },

            consentNote() {
                return this.lead.customer.casl_consent.confirmed_note;
            },

            consentNoteIsEmpty() {
                return !this.inputConsentNoteTrimmed;
            },

            consentNoteIsValid() {
                return !this.consentNoteIsEmpty &&
                    this.inputConsentNoteTrimmed.length > 12 &&
                    this.inputConsentNoteTrimmed.split(' ').length > 1;
            },

            consentType() {
                return this.lead.customer.casl_consent.confirmed_type;
            },

            consentReasonText() {
                if (this.lead.customer.isConfirmedConsent) {
                    return this.$t(
                        'consent.consentReasonConfirmed',
                        { date: new ActivixDate(this.lead.customer.casl_consent.confirmed_at).toHumanShort(false) },
                    );
                }

                if (!this.lead.customer.casl_consent.limit_event || !this.lead.customer.casl_consent.limit_event_date) {
                    return '';
                }

                const limitEventText = CaslConsentLimitEvent.getTranslation(this.lead.customer.casl_consent.limit_event);
                const limitEventDate = new ActivixDate(this.lead.customer.casl_consent.limit_event_date).toHumanShort(false);

                return `${limitEventText} ${limitEventDate}`;
            },

            consentStateText() {
                if (this.lead.customer.isConfirmedConsent) {
                    return this.$t('consent.confirmedState');
                }

                return this.$t('consent.temporaryState');
            },

            consentLimitDate() {
                return new ActivixDate(this.lead.customer.casl_consent.limit_date).toHumanShort(false);
            },

            defaultCustomerEmail() {
                const home = this.activeLeadEmails.find(email => email.type === LeadEmailType.HOME);
                const work = this.activeLeadEmails.find(email => email.type === LeadEmailType.WORK);
                const other = this.activeLeadEmails.find(email => email.type === LeadEmailType.OTHER);

                return home?.email || work?.email || other?.email || null;
            },

            doubleOptInDisabled() {
                return this.isConfirmed || !this.activeLeadEmails.length;
            },

            doubleOptInOptionSelected() {
                return this.selectedConsentType === CaslConsentType.DOUBLE_OPT_IN;
            },

            doubleOptInTooltip() {
                if (!this.activeLeadEmails.length) {
                    return this.$t('consent.missingEmail');
                }

                return '';
            },

            hasConsentDoubleOptInDate() {
                return !!this.lead.customer.casl_consent.confirmation.sent_at;
            },

            inputConsentNoteTrimmed() {
                return (this.inputConsentNote || '').trim();
            },

            isConfirmed() {
                return this.lead.customer.isConfirmedConsent;
            },

            lawLink() {
                return {
                    fr: 'https://crtc.gc.ca/fra/internet/anti.htm',
                    en: 'https://crtc.gc.ca/eng/internet/anti.htm',
                }[this.$i18n.locale];
            },

            manualOptionSelected() {
                return this.selectedConsentType === CaslConsentType.MANUAL;
            },

            saveButtonDisabled() {
                return (this.consentType !== CaslConsentType.MANUAL && this.isConfirmed) ||
                    !this.manualOptionSelected ||
                    !this.consentNoteIsValid;
            },

            saveButtonTooltip() {
                if (!this.manualOptionSelected) {
                    return '';
                }

                if (this.consentNoteIsEmpty) {
                    return this.$t('consent.consentNoteIsRequired');
                }

                if (!this.consentNoteIsValid) {
                    return this.$t('consent.consentNoteIsTooShort');
                }

                return '';
            },

            saveButtonVisible() {
                return this.manualOptionSelected;
            },

            sendButtonDisabled() {
                return !this.doubleOptInOptionSelected ||
                    this.hasConsentDoubleOptInDate ||
                    !this.selectedEmail;
            },

            sendButtonTooltip() {
                if (this.lead.customer.casl_consent.confirmed_at) {
                    return this.$t('consent.alreadyConfirmed');
                }

                if (this.lead.customer.casl_consent.confirmation.sent_at) {
                    return this.$t('consent.waitingForDoubleOptIn');
                }

                return '';
            },

            sendButtonVisible() {
                return this.doubleOptInOptionSelected || this.selectedConsentType === null;
            },
        },

        watch: {
            consentNote: {
                immediate: true,
                handler() {
                    this.inputConsentNote = this.consentNote;
                },
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['appendNewError', 'fetchLead', 'updateLead']),

            additionalInfoToggle() {
                this.additionalInfoShown = !this.additionalInfoShown;
            },

            close() {
                this.$refs.modal.hide();
                this.$emit('close');
            },

            getEmailTypeText(item) {
                const type = {
                    home: this.$t('consent.emailTypes.home'),
                    work: this.$t('consent.emailTypes.work'),
                    other: this.$t('consent.emailTypes.other'),
                }[item.type];

                return `${type.toUpperCase()} - ${item.email}`;
            },

            getEmailOption(emailAddress) {
                return this.activeLeadEmails.find(email => email.email === emailAddress);
            },

            async sendConsentEmail() {
                this.$wait.start('sending.customer.consent');

                try {
                    const response = await this.$axios.post(`v1/customers/${this.lead.customer.id}/consent/email`, {
                        email: this.selectedEmail.email,
                        leadId: this.lead.id,
                    });

                    this.updateLead({
                        id: this.lead.id,
                        newData: {
                            customer: response.data.data,
                        },
                    });

                    this.close();
                } catch (error) {
                    if (isNetworkDisconnectedError(error) || error.response?.status === 403) {
                        return;
                    }

                    if (error.response?.status === 422) {
                        const message = Object.values(error.response.data.errors)[0][0] || '';

                        if (message) {
                            this.$notify.warning(message);
                        } else {
                            this.$notify.warning(this.$t('consent.errors.sendConsentEmailFailed'));
                        }

                        return;
                    }

                    this.appendNewError({
                        code: '0117',
                        display: true,
                        error,
                    });
                } finally {
                    this.$wait.end('sending.customer.consent');
                }
            },

            async updateCustomer() {
                this.$wait.start('updating.customer.consent');

                const customerId = this.lead.customer.id;

                try {
                    const response = await this.$axios.put(`v1/customers/${customerId}/consent`, {
                        confirmed_at: this.isConfirmed ? null : new ActivixDate('now').toString(),
                        confirmed_type: this.isConfirmed ? null : CaslConsentType.MANUAL,
                        confirmed_note: this.isConfirmed ? null : this.inputConsentNoteTrimmed,
                    });

                    this.updateLead({
                        id: this.lead.id,
                        newData: {
                            customer: response.data.data,
                        },
                    });

                    this.close();
                } catch (error) {
                    if (isNetworkDisconnectedError(error) || error.response?.status === 403) {
                        return;
                    }

                    if (error.response?.status === 422) {
                        const message = Object.values(error.response.data.errors)[0][0] || '';

                        if (message) {
                            this.$notify.warning(message);
                        } else {
                            this.$notify.warning(this.$t('consent.errors.saveManualConsentFailed'));
                        }

                        return;
                    }

                    this.appendNewError({
                        code: '0118',
                        display: true,
                        error,
                    });
                } finally {
                    this.warningModalOpen = false;
                    this.$wait.end('updating.customer.consent');
                }
            },

            updateSelectedEmail(email) {
                this.selectedEmail = email;
            },

            async onBeforeOpen({ leadId }) {
                this.lead = await this.fetchLead(leadId);

                this.inputConsentNote = this.lead.customer.casl_consent.confirmed_note;

                this.selectedEmail = this.getEmailOption(this.lead.customer.casl_consent.confirmation.contact_value);

                if (!this.selectedEmail) {
                    this.selectedEmail = this.getEmailOption(this.defaultCustomerEmail);
                }

                if (this.lead.customer.isConfirmedConsent && this.lead.customer.isManualConsent) {
                    this.selectedConsentType = CaslConsentType.MANUAL;
                } else if (this.lead.customer.casl_consent.confirmation.sent_at && !this.lead.customer.isManualConsent) {
                    this.selectedConsentType = CaslConsentType.DOUBLE_OPT_IN;
                } else {
                    this.selectedConsentType = null;
                }

                this.loading = false;
            },

            onClosed() {
                this.lead = new Lead();
                this.loading = true;
                this.additionalInfoShown = false;
                this.selectedConsentType = null;
                this.selectedEmail = null;
                this.inputConsentNote = null;
                this.$wait.end('updating.customer.consent');
                this.$wait.end('sending.customer.consent');

                this.$emit('closed');
            },

            onDoubleOptInSelection() {
                this.selectedConsentType = CaslConsentType.DOUBLE_OPT_IN;
            },

            onManualSelection() {
                this.selectedConsentType = CaslConsentType.MANUAL;
            },

            onSave() {
                if (this.isConfirmed) {
                    this.updateCustomer();
                } else {
                    this.warningModalOpen = true;
                }
            },
        },
    };
</script>
