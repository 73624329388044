import Enum from './Enum.js';

export default class LeadVehicleFrequency extends Enum {
    static get entries() {
        return {
            bi_monthly: 'bi_monthly',
            monthly: 'monthly',
            one_payment: 'one_payment',
            two_weeks: 'two_weeks',
            weekly: 'weekly',
        };
    }

    static get BI_MONTHLY() {
        return this.entries.bi_monthly;
    }

    static get MONTHLY() {
        return this.entries.monthly;
    }

    static get ONE_PAYMENT() {
        return this.entries.one_payment;
    }

    static get TWO_WEEKS() {
        return this.entries.two_weeks;
    }

    static get WEEKLY() {
        return this.entries.weekly;
    }

    static get translationGroup() {
        return 'leadVehicleFrequencies';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
