import Enum from './Enum.js';

export default class CaslConsentLimitEvent extends Enum {
    static get entries() {
        return {
            appointment: 'appointment',
            communication: 'communication',
            initial: 'initial',
            sale: 'sale',
            visit: 'visit',
            contract: 'contract',
        };
    }

    static get APPOINTMENT() {
        return this.entries.appointment;
    }

    static get COMMUNICATION() {
        return this.entries.communication;
    }

    static get INITIAL() {
        return this.entries.initial;
    }

    static get SALE() {
        return this.entries.sale;
    }

    static get VISIT() {
        return this.entries.visit;
    }

    static get CONTRACT() {
        return this.entries.contract;
    }

    static get translationGroup() {
        return 'consent.events';
    }
}
