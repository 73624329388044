import Enum from './Enum.js';

export default class LeadVehicleIntention extends Enum {
    static get entries() {
        return {
            buyBack: 'buyBack',
            refinance: 'refinance',
            renewal: 'renewal',
            return: 'return',
        };
    }

    static get BUY_BACK() {
        return this.entries.diesel;
    }

    static get REFINANCE() {
        return this.entries.refinance;
    }

    static get RENEWAL() {
        return this.entries.renewal;
    }

    static get RETURN() {
        return this.entries.return;
    }

    static get translationGroup() {
        return 'leadVehicleIntentions';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
