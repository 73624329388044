import Enum from './Enum.js';

export default class LeadVehicleStockState extends Enum {
    static get entries() {
        return {
            locate: 'locate',
            notAvailable: 'notAvailable',
            order: 'order',
            stock: 'stock',
        };
    }

    static get LOCATE() {
        return this.entries.locate;
    }

    static get NOT_AVAILABLE() {
        return this.entries.notAvailable;
    }

    static get ORDER() {
        return this.entries.order;
    }

    static get STOCK() {
        return this.entries.stock;
    }

    static get translationGroup() {
        return 'leadVehicleStockStates';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
