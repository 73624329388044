import Enum from './Enum.js';
import i18n from '../plugins/vue-i18n.js';

export default class LeadVehicleTerm extends Enum {
    static get entries() {
        return {
            12: '12',
            24: '24',
            27: '27',
            32: '32',
            33: '33',
            34: '34',
            36: '36',
            38: '38',
            39: '39',
            40: '40',
            42: '42',
            45: '45',
            48: '48',
            51: '51',
            52: '52',
            54: '54',
            60: '60',
            64: '64',
            66: '66',
            72: '72',
            78: '78',
            84: '84',
            90: '90',
            96: '96',
            108: '108',
            120: '120',
            132: '132',
            144: '144',
            156: '156',
            168: '168',
            180: '180',
            192: '192',
            204: '204',
            216: '216',
            228: '228',
            240: '240',
        };
    }

    static get 12() {
        return this.entries['12'];
    }

    static get 24() {
        return this.entries['24'];
    }

    static get 27() {
        return this.entries['27'];
    }

    static get 32() {
        return this.entries['32'];
    }

    static get 33() {
        return this.entries['33'];
    }

    static get 34() {
        return this.entries['34'];
    }

    static get 36() {
        return this.entries['36'];
    }

    static get 38() {
        return this.entries['38'];
    }

    static get 39() {
        return this.entries['39'];
    }

    static get 40() {
        return this.entries['40'];
    }

    static get 42() {
        return this.entries['42'];
    }

    static get 45() {
        return this.entries['45'];
    }

    static get 48() {
        return this.entries['48'];
    }

    static get 51() {
        return this.entries['51'];
    }

    static get 52() {
        return this.entries['52'];
    }

    static get 54() {
        return this.entries['54'];
    }

    static get 60() {
        return this.entries['60'];
    }

    static get 64() {
        return this.entries['64'];
    }

    static get 66() {
        return this.entries['66'];
    }

    static get 72() {
        return this.entries['72'];
    }

    static get 78() {
        return this.entries['78'];
    }

    static get 84() {
        return this.entries['84'];
    }

    static get 90() {
        return this.entries['90'];
    }

    static get 96() {
        return this.entries['96'];
    }

    static get 108() {
        return this.entries['108'];
    }

    static get 120() {
        return this.entries['120'];
    }

    static get 132() {
        return this.entries['132'];
    }

    static get 144() {
        return this.entries['144'];
    }

    static get 156() {
        return this.entries['156'];
    }

    static get 168() {
        return this.entries['168'];
    }

    static get 180() {
        return this.entries['180'];
    }

    static get 192() {
        return this.entries['192'];
    }

    static get 204() {
        return this.entries['204'];
    }

    static get 216() {
        return this.entries['216'];
    }

    static get 228() {
        return this.entries['228'];
    }

    static get 240() {
        return this.entries['240'];
    }

    static getTranslation(value) {
        const key = this.getKey(value);

        return key ? `${key} ${i18n.t('clientCard.vehicles.months')}` : '';
    }

    static selectOptions(idName = '', labelName = '') {
        return this.values
            .map(id => {
                return {
                    [idName || 'id']: id,
                    [labelName || 'label']: this.getTranslation(id),
                };
            })
            .sort((a, b) => b[labelName || 'label'] - a[labelName || 'label']);
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
